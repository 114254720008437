import React from 'react'

import Layout from '../components/layout'
import { Resources } from '../components/Resources'

export const ResourcesPage = () => (
  <Layout seo={{ title: 'Patient Forms & Resources' }}>
    <Resources />
  </Layout>
)

export default ResourcesPage
