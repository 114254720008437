import React from 'react'
import { Link } from 'gatsby'

import { INTERNAL_URLS, EXTERNAL_URLS } from '../../config/urls'

import landingStyles from '../Landing/Landing.module.scss'
import styles from './Resources.module.scss'

export class Resources extends React.Component {
  render() {
    return (
      <div>
        <div className={styles.page}>
          <div className={styles.pageContent}>
            <div className={styles.pageHeader}>
              <div className={landingStyles.aboutSectionBorder} />
              <h2 className={styles.headerTitle}>
                Patient Forms & Resources
              </h2>
              <div className={styles.headerSubtitle}>
                To save yourself time before visiting our office, you can download and fill out forms using the links below.
              </div>
            </div>
            <h2 className={styles.resourcesSectionHeader}>
              New Patient Forms
            </h2>
            <section className={styles.resourcesList}>
              <div className={styles.resourcesListItem}>
                <a href="/" className={styles.resourcesListItemLink}>
                  Authorization for Release of Medical Information (PDF)
                </a>
                <div>
                  Allows patients to authorize the disclosure of their health information to a designated individual, company, agency, or facility.
                </div>
              </div>
              <div className={styles.resourcesListItem}>
                <a href="/" className={styles.resourcesListItemLink}>
                  Authorization and Consent for Treatment (PDF)
                </a>
                <div>
                  All patients must provide their consent for treatment, communications (calls, emails, and text messaging), and agreement of financial responsibility.
                </div>
              </div>
              <div className={styles.resourcesListItem}>
                <a href="/" className={styles.resourcesListItemLink}>
                  Preferred Contacts (PDF)
                </a>
                <div>
                  Patients are encouraged to complete and return the Preferred Contacts Form but it is not required.
                </div>
              </div>
              <div className={styles.resourcesListItem}>
                <a href="/" className={styles.resourcesListItemLink}>
                  Virtual Visit Policy (PDF)
                </a>
                <div>
                  This policy describes the process for the documentation, maintenance, and transmission of information using virtual visit technology.
                </div>
              </div>
            </section>

            <h2 className={styles.resourcesSectionHeader}>
              Office Policies
            </h2>
            <section className={styles.resourcesList}>
              <div className={styles.resourcesListItem}>
                <a href="/" className={styles.resourcesListItemLink}>
                  Authorization for Release of Medical Information (PDF)
                </a>
                <div>
                  Allows patients to authorize the disclosure of their health information to a designated individual, company, agency, or facility.
                </div>
              </div>
              <div className={styles.resourcesListItem}>
                <a href="/" className={styles.resourcesListItemLink}>
                  Authorization and Consent for Treatment (PDF)
                </a>
                <div>
                  All patients must provide their consent for treatment, communications (calls, emails, and text messaging), and agreement of financial responsibility.
                </div>
              </div>
              <div className={styles.resourcesListItem}>
                <a href="/" className={styles.resourcesListItemLink}>
                  Preferred Contacts (PDF)
                </a>
                <div>
                  Patients are encouraged to complete and return the Preferred Contacts Form but it is not required.
                </div>
              </div>
              <div className={styles.resourcesListItem}>
                <a href="/" className={styles.resourcesListItemLink}>
                  Virtual Visit Policy (PDF)
                </a>
                <div>
                  This policy describes the process for the documentation, maintenance, and transmission of information using virtual visit technology.
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    )
  }
}
